import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./Suggest.css";

const Suggest = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div className="w-100 h-100">
            <h3>Suggestion Content</h3>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Suggest;
