import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import BannerSlider from "./BannerSlider";
import CategoryMenu from "./CategoryMenu";
import Community from "./Community";
import PromoSlider2 from "./PromoSlider2";
import PodCastSlider from "../podcast/PodCastSlider";
import "./WelcomeHome2.css";

const WelcomeHome2 = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <BannerSlider />
        <CategoryMenu />
        <Community />
        <PromoSlider2 />
        <PodCastSlider/>

        <div className="container-fluid mt-1 w-100" style={{height:"10px"}}>
          <div>&nbsp;</div>
        </div>

      </div>
    </PublicLayout>
  );
};

export default WelcomeHome2;
