import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import "./BannerSlider.css";

const BannerSlider = () => {
  const bannerUrl1 = "/assets/upload/banner1.jpeg";
  const bannerUrl2 = "/assets/upload/banner2.jpg";
  const bannerUrl3 = "/assets/upload/banner3.jpeg";
  return (
    <div className="w-100  mt-2 bannerSlider" style={{ height: "120px" }}>
      <div
        className="d-flex align-items-center h-100 py-1"
        style={{ overflow: "hidden" }}
      >
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={false}
          modules={[Autoplay, Pagination]}
          className="bannerSwiper w-100 px-2"
        >
          <SwiperSlide className="bannerSwiperSlide">
            <div
              className="d-flex justify-content-center align-items-center bg-primary rounded-3"
              style={{
                width: "250px",
                height: "120px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url('${bannerUrl1}')`,
              }}
            >
              <div
                className="d-flex flex-column w-100 h-100 rounded-3"
                style={{ background: "rgba(0,0,0,0.3)" }}
              >
                <div className="mt-auto w-100 px-2">
                  <p
                    className="text-white"
                    style={{
                      lineHeight: "16px",
                      fontWeight: "800",
                      maxWidth: "100%",
                    }}
                  >
                    iPhone 17 <br></br>
                    Ternyata <br></br>
                    Banyak Bugs
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide">
            <div
              className="d-flex justify-content-center align-items-center bg-primary rounded-3"
              style={{
                width: "250px",
                height: "120px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url('${bannerUrl2}')`,
              }}
            >
              <div
                className="d-flex flex-column w-100 h-100 rounded-3"
                style={{ background: "rgba(0,0,0,0.3)" }}
              >
                <div className="mt-auto w-100 px-2">
                  <p
                    className="text-white"
                    style={{
                      lineHeight: "16px",
                      fontWeight: "800",
                      maxWidth: "100%",
                    }}
                  >
                    Promo <br></br>
                    Baterai 50% <br></br>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide">
            <div
              className="d-flex justify-content-center align-items-center bg-primary rounded-3"
              style={{
                width: "250px",
                height: "120px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url('${bannerUrl3}')`,
              }}
            >
              <div
                className="d-flex flex-column w-100 h-100 rounded-3"
                style={{ background: "rgba(0,0,0,0.3)" }}
              >
                <div className="mt-auto w-100 px-2">
                  <p
                    className="text-white"
                    style={{
                      lineHeight: "16px",
                      fontWeight: "800",
                      maxWidth: "100%",
                    }}
                  >
                    50 Layanan Baru <br></br>
                    Service <br></br>
                    24 Jam
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
export default BannerSlider;
