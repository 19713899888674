import React from 'react';

const BaseLayout = ({ children }) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100">
      {children}
    </div>
  );
};

export default BaseLayout;