import React from "react";
import Slider from "react-slick";
import "./GallerySlider.css";

import { useTranslation } from "react-i18next";

import ImageBox from "./GalleryImageBox";
import SliderMenuTab from "../slidermenutab/SliderMenuTab";

const GallerySlider = () => {
  const { t, i18n } = useTranslation();

  var settingsH = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex flex-column flex-grow-1">
        
        <SliderMenuTab/>

        <div className="row g-0 flex-grow-1">
          <div className="col" style={{ overflowX: "hidden",overflowY: "hidden" }}>
            <Slider {...settingsH}>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery1.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery1.png" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery1.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery1.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery2.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery2.png" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery2.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery2.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery3.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery3.png" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery3.png" />
                    </div>
                    <div className="col">
                      <ImageBox backgroundImage="/assets/upload/gallery3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default GallerySlider;
