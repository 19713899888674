import React from 'react';
import PublicLayout from '../../_layout/PublicLayout';
const Reels = () => {
  return (
    <PublicLayout>
        <div className='d-flex justify-content-center align-items-center w-100 h-100'>
          <h1>Reel Content</h1>
        </div>
    </PublicLayout>
  );
};

export default Reels;
