import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import "./Community.css";

const Community = () => {
  const banner2Url1 = "/assets/upload/repair1.jpg";
  const banner2Url2 = "/assets/upload/repair2.jpg";
  const banner2Url3 = "/assets/upload/repair3.jpg";
  const banner2Url4 = "/assets/upload/repair4.jpg";
  const banner2Url5 = "/assets/upload/repair5.jpg";
  const banner2Url6 = "/assets/upload/repair6.jpg";
  return (
    <>
      <div className="d-flex mt-1 px-2 mx-1">
        <div style={{ fontSize: "13px", fontWeight: "600" }}>
          Pencarian Berdasar Group & Komunitas
        </div>
      </div>

      <div className="d-flex mt-1 px-2 mx-1 flex-wrap">
        <div
          className="px-2 rounded-pill text-primary mb-1 me-2"
          style={{
            border: "1px solid #33cc33",
            fontSize: "8px",
            fontWeight: "600",
          }}
        >
          Alumni Borneofalsher
        </div>
        <div
          className="px-2 rounded-pill text-primary mb-1 me-2"
          style={{
            border: "1px solid #33cc33",
            fontSize: "8px",
            fontWeight: "600",
          }}
        >
          Komunitas Teknisi Regional Bandung
        </div>
        <div
          className="px-2 rounded-pill text-primary mb-1 me-2"
          style={{
            border: "1px solid #33cc33",
            fontSize: "8px",
            fontWeight: "600",
          }}
        >
          iColor Service Repair
        </div>
        <div
          className="px-2 rounded-pill text-primary mb-1 me-2"
          style={{
            border: "1px solid #33cc33",
            fontSize: "8px",
            fontWeight: "600",
          }}
        >
          Komunitas Teknisi Garangan
        </div>
        <div
          className="px-2 rounded-pill text-primary mb-1 me-2 d-flex align-items-center justify-content-between "
          style={{
            border: "1px solid #33cc33",
            fontSize: "8px",
            fontWeight: "600",
          }}
        >
          <input
            className="border-0 searchCommunity px-0"
            type="text"
            name=""
            id=""
            placeholder="Cari group.."
            style={{ width: "60px" }}
          />
          <span>
            <i className="bi bi-search"></i>
          </span>
        </div>
      </div>

      <div className="d-flex px-2 mx-1">
        <div style={{ fontSize: "13px", fontWeight: "600" }}>
          Hasil Pencarian
        </div>
      </div>
      <div className="d-flex px-2 mx-1">
        <div style={{ fontSize: "11px", fontWeight: "600" }}>
          <span className="text-primary">Komunitas Teknisi Garangan</span>{" "}
          Terdekat ..
        </div>
      </div>

      <div className="d-flex mt-1 px-2">
        <Swiper
          pagination={false}
          spaceBetween={2}
          modules={[Pagination]}
          className="bannerSwiper2 w-100"
        >
          <SwiperSlide className="bannerSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner2Url1}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smartphone
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iRoom Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 143 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner2Url3}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smart TV
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iService Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 110 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner2Url5}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Console
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iServices Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 74 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner2Url1}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smartphone
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iRoom Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 143 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bannerSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner2Url3}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smart TV
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iService Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 110 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};
export default Community;
