import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./Privacy.css";

const Privacy = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div className="w-100 h-100 p-3" style={{overflowY:"auto"}}>
          <h3>Privacy Policy</h3>

          <p>Privacy Policy for Phone Services App</p>
          <p></p>
          <p>Effective Date: 31-05-2024</p>
          <p></p>
          <p>1. Introduction</p>
          <p></p>
          <p>
            Welcome to Gizmap . We are
            committed to protecting and respecting your privacy. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            information when you use our mobile application Gizmap.
          </p>
          <p></p>
          <p>2. Information We Collect</p>
          <p></p>
          <p>We may collect and process the following types of information:</p>
          <p></p>
          <p>
            Personal Data: When you register or use the App, we may collect
            personal information such as your name, email address, phone number,
            and any other information you provide.
          </p>
          <p>
            Usage Data: Information about your use of the App, including call
            logs, messages, and app settings.
          </p>
          <p>
            Device Information: Information about your device, such as device
            type, operating system, unique device identifiers, and mobile
            network information.
          </p>
          <p>
            Location Data: With your consent, we may collect and process
            information about your location.
          </p>
          <p>3. How We Use Your Information</p>
          <p></p>
          <p>We use the information we collect in the following ways:</p>
          <p></p>
          <p>To provide, operate, and maintain the App.</p>
          <p>To improve, personalize, and expand our services.</p>
          <p>To understand and analyze how you use our App.</p>
          <p>To develop new products, services, features, and functionality.</p>
          <p>
            To communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the App, and for marketing
            and promotional purposes.
          </p>
          <p>To process your transactions and manage your orders.</p>
          <p>To find and prevent fraud.</p>
          <p>To comply with legal obligations.</p>
          <p>4. Sharing Your Information</p>
          <p></p>
          <p>We may share your information in the following situations:</p>
          <p></p>
          <p>
            With Service Providers: We may share your information with
            third-party vendors, service providers, contractors, or agents who
            perform services on our behalf.
          </p>
          <p>
            For Legal Reasons: We may disclose your information if we believe it
            is necessary to comply with a legal obligation or to protect and
            defend our rights or property.
          </p>
          <p>
            Business Transfers: In the event of a merger, acquisition, or sale
            of all or a portion of our assets, your information may be
            transferred to the new owner.
          </p>
          <p>5. Data Security</p>
          <p></p>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal data from unauthorized access, use, or
            disclosure. However, please note that no method of transmission over
            the internet or electronic storage is completely secure.
          </p>
          <p></p>
          <p>6. Your Privacy Rights</p>
          <p></p>
          <p>
            Depending on your location, you may have the following rights
            regarding your personal data:
          </p>
          <p></p>
          <p>The right to access and obtain a copy of your personal data.</p>
          <p>
            The right to rectify any inaccurate or incomplete personal data.
          </p>
          <p>The right to erase your personal data under certain conditions.</p>
          <p>
            The right to restrict or object to the processing of your personal
            data.
          </p>
          <p>The right to data portability.</p>
          <p>
            The right to withdraw consent at any time, where we rely on your
            consent to process your personal data.
          </p>
          <p>
            To exercise these rights, please contact us at [Contact Email
            Address].
          </p>
          <p></p>
          <p>7. Children's Privacy</p>
          <p></p>
          <p>
            Our App is not intended for children under the age of 13. We do not
            knowingly collect personal data from children under 13. If we become
            aware that we have collected personal data from a child under 13, we
            will take steps to delete such information from our files as soon as
            possible.
          </p>
          <p></p>
          <p>8. Changes to This Privacy Policy</p>
          <p></p>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p></p>
          <p>9. Contact Us</p>
          <p></p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p></p>
          <p>Gizmap</p>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Privacy;
