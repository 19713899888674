import React from "react";
import Slider from "react-slick";
import "./NewsSlider.css";

import { useTranslation } from "react-i18next";

import NewsImageBox from "./NewsImageBox";
import SliderMenuTab from "../slidermenutab/SliderMenuTab";

const NewsSlider = () => {
  const { t, i18n } = useTranslation();

  var settingsH = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex flex-column flex-grow-1">
        
        <SliderMenuTab/>

        <div className="row g-0 flex-grow-1">
          <div className="col" style={{ overflowX: "hidden",overflowY: "hidden" }}>
            <Slider {...settingsH}>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten1.jpg" />
                    </div>
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten2.jpeg" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten3.jpg" />
                    </div>
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten4.jpg" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten5.jpeg" />
                    </div>
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten6.jpeg" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten7.jpeg" />
                    </div>
                    <div className="col">
                      <NewsImageBox backgroundImage="/assets/upload/konten8.jpg" />
                    </div>
                  </div>
                </div>
              </div>
              
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsSlider;
