import React from 'react';
import { Route, Redirect, Routes, Outlet } from 'react-router-dom';
import Home from './components/publics/page/welcome/Home';
import Reels from './components/publics/page/reels/Reels';
import Gallery from './components/publics/page/gallery/Gallery';
import GalleryDetail from './components/publics/page/gallerydetail/GalleryDetail';
// import WelcomeHome from './components/publics/page/home/WelcomeHome';
import WelcomeHome2 from './components/publics/page/home/WelcomeHome2';
import News from './components/publics/page/news/News';
import NewsDetail from './components/publics/page/newsdetail/NewsDetail';
import Promo from './components/publics/page/promo/Promo';
import PromoDetail from './components/publics/page/promodetail/PromoDetail';
import Suggest from './components/publics/page/suggest/Suggest';
import MapLocation from './components/publics/page/maplocation/MapLocation';
import Join from './components/publics/page/join/Join';
import Login from './components/publics/page/login/Login';
import PodCast from './components/publics/page/podcast/PodCast';
import UserProfile from './components/publics/page/user/UserProfile';
import Inbox from './components/publics/page/message/Inbox';
import Notification from './components/publics/page/notification/Notification';
import Privacy from './components/publics/page/privacy/Privacy';
import Terms from './components/publics/page/terms/Terms';

const Troute = () => {
    return (
      <Routes>
        {/* Admin Routes */}
        {/* <Route path="/admin" element={<AdminLogin />} />
        <Route element={<ProtectedRoutes redirectPath={'/admin'} />}>
            <Route path="/admin/home" element={<AdminDashboard />} />
        </Route> */}
        
  
        {/*
            <Route path="/admin">
          <BaseLayout>
            <AdminLogin />
          </BaseLayout>
        </Route>
        <Route path="/admin/login">
          <BaseLayout>
            <AdminLogin />
          </BaseLayout>
        </Route>
  
        <Route path="/admin/dashboard">
          <AdminLayout>
            <AdminDashboard />
          </AdminLayout>
        </Route>
        <Route path="/admin/user" exact>
          <AdminLayout>
            <AdminUser />
          </AdminLayout>
        </Route>
        <Route path="/admin/user/form">
          <AdminLayout>
            <AdminUserForm />
          </AdminLayout>
        </Route>
        <Route path="/admin/user/report">
          <AdminLayout>
            <AdminUserReport />
          </AdminLayout>
        </Route>
  
        <Route path="/public">
          <PublicLayout>
          </PublicLayout>
        </Route>
      */}
  
        {/* Public Routes */}
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/detail" element={<GalleryDetail />} />
        <Route path="/home" element={<WelcomeHome2 />} />
        <Route path="/reels" element={<Reels />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/detail" element={<NewsDetail />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/promo/detail" element={<PromoDetail />} />
        <Route path="/suggestion" element={<Suggest />} />
        <Route path="/location" element={<MapLocation />} />
        <Route path="/podcast" element={<PodCast />} />
        <Route path="/user/profile" element={<UserProfile />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />

  
        {/* Base Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        
      </Routes>
    );
  };
  
  export default Troute;