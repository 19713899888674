import React from "react";
// import GoogleMapReact from "google-map-react";
import PublicLayout from "../../_layout/PublicLayout";
import PanelInfo from "../info/PanelInfo";
import LocationImageSlider from "./LocationImageSlider";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const MapLocation = () => {
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627,
  //   },
  //   zoom: 11,
  // };
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div
          className="d-flex flex-column flex-grow-1 w-100"
          style={{ overflowY: "hide", overflowX: "hidden" }}
        >
          <PanelInfo />

          <div className="row g-0 py-1 align-items-center">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between px-1 py-1">
                <div
                  className="fw-bold PanelSliderText"
                  style={{ fontSize: "13px" }}
                >
                  Peta Lokasi{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col">
              <div
                className="d-flex w-100 bg-primary"
                style={{ height: "250px" }}
              >
                <div style={{ height: "100%", width: "100%" }}>

                  <iframe
                    id="mapIframe"
                    title="map location"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15859.530076933188!2d106.8477278!3d-6.4091315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69eb8e8866683d%3A0xf6432e389110cbcf!2sKlinik%20Gadget!5e0!3m2!1sen!2sid!4v1714647935044!5m2!1sen!2sid"
                    style={{ border: 0,height:"100%",width:"100%"}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />

                  {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={59.955413}
                      lng={30.337844}
                      text="My Marker"
                    />
                  </GoogleMapReact> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col px-1">
              <div
                className="d-flex w-100 border border-1 border-dark my-1"
                style={{ minHeight: "30px", borderRadius: "2px" }}
              >
                <p className="p-2 m-0" style={{ fontSize: "11px" }}>
                  Klinik gadget menyediakan jasa perbaikan smartphone baik
                  software dan hardware. Bergaransi <br></br>
                  Jam Buka <br></br>
                  Senin - Sabtu 9:00 - 17:00
                </p>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col px-1">
              <div className="fw-bold " style={{ fontSize: "13px" }}>
                Foto Toko
              </div>
            </div>
          </div>

          <LocationImageSlider />
        </div>
      </div>
    </PublicLayout>
  );
};

export default MapLocation;
