import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import BaseLayout from "../../_layout/BaseLayout";
import "./Login.css";

import Logo from "../../../global/logo/Logo";
import AuthServices from "../../../../services/AuthServices";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({email:'',password:''});
  const [userData, setUserData] = useState(null);
  const emailInputRef = useRef(null);

  useEffect(() => {

    const userJson = localStorage.getItem("userData");
    if (userJson) {
      try {
        const user = JSON.parse(userJson);
        setUserData((prevUserData) => ({
          ...prevUserData,
          ...user,
        }));
        setLoginData({ ...loginData, lang: user.lang });
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }

    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const handleEmailChange = (e) => {
    setLoginData({ ...loginData, email: e.target.value });
  }
  const handlePasswordChange = (e) => {
    setLoginData({ ...loginData, password: e.target.value });
  }

  const handleLoginClick = async () => {

    setLoading(true);

    try {
      let res = await AuthServices.sigIn(loginData);
      if (res.code === 200) {

        toast.success(res.message, {
          theme: "dark",
          position: "bottom-center",
          transition: Zoom,
        });

        let resAuth = await AuthServices.show(res.data.id,res.accessToken,res.lang);
        
        if(resAuth.code===200){

          const userInfo = resAuth.data;
          userInfo.type = userInfo.role;
          userInfo.accessToken = res.accessToken;
  
          setUserData((prevUserData) => ({
            ...prevUserData,
            ...userInfo,
          }));
          

          localStorage.setItem(
            "userData",
            JSON.stringify(userInfo)
          );

          setTimeout(() => {
            navigate("/gallery");                        
          },500);

        }
        
      } else {
        
        toast.error(res.message, {
          theme: "dark",
          position: "bottom-center",
          transition: Zoom,
        });
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);

      toast.error("An error occurred during sign in", {
        theme: "dark",
        position: "bottom-center",
        transition: Zoom,
      });
    }

  }

  return (
    <BaseLayout>
      <div className="d-flex flex-column w-100 h-100 bg-primary">
        <div className="d-flex justify-content-center mt-5 mb-2">
          <Logo width="200px" />
        </div>

        <div className="d-flex px-5 justify-content-center">
          <p
            className="text-white"
            style={{ fontSize: "24px", fontWeight: "700" }}
          >
            Login
          </p>
        </div>

        <div className="d-flex px-5">
          <p
            className="text-white"
            style={{ fontSize: "11px", fontWeight: "500" }}
          >
            Belum mempunyai Akun ? Silahkan{" "}
            <a className="text-white ms-1" href="/join">
              Gabung
            </a>
            .
          </p>
        </div>

        <div className="d-flex flex-column px-5">
          <div
            className="text-white mb-1"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Email
          </div>
          <input
            type="email"
            className="form-control mb-2"
            placeholder="masukkan email"
            style={{ fontSize: "12px" }}
            onChange={handleEmailChange}
            ref={emailInputRef}
          />

          <div
            className="text-white mb-1"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Password
          </div>
          <input
            type="password"
            className="form-control mb-3"
            placeholder="masukkan password"
            style={{ fontSize: "12px" }}
            onChange={handlePasswordChange}

          />

          <div className="d-grid gap-2 mb-3">
            <button className="btn btn-secondary" type="button" onClick={handleLoginClick}>
              <div className="d-flex align-items-center justify-content-center">

              {loading && (
                <div className="spinner-grow spinner-grow-sm me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}

                <span>Masuk</span>

              </div>
            </button>
          </div>

          <div
            className="mt-1"
            style={{ height: "1px", borderTop: "1px solid white" }}
          ></div>

          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "-10px" }}
          >
            <div
              className="d-flex justify-content-center bg-primary text-white"
              style={{ fontSize: "11px", width: "60px" }}
            >
              atau
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="login-with-google-btn mt-3"
                style={{ width: "216px" }}
              >
                Sign in with Google
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a href="#!" className="fb connect">
                Sign in with Facebook
              </a>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <a
                href="/"
                className="text-white"
                style={{
                  textDecoration: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {" "}
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Kembali
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </BaseLayout>
  );
};

export default Login;
