import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SideBarProfile.css";
const SideBarProfile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userJson = localStorage.getItem("userData");
    if (userJson) {
      var user = JSON.parse(userJson);
      setUserData(user);
    }
  }, []);

  const handleClickEdit = () => {
    navigate(`/user/profile?type=${userData.type}&q=${userData.id}`);
  };

  return (
    <div className="d-flex flex-column w-100 userProfile mb-3 px-2">
      <div className="postion-relative">
        <div
          onClick={handleClickEdit}
          className="position-absolute"
          style={{ top: "10px", right: "20px" }}
        >
          <i className="bi bi-pencil-square"></i>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div
          className="bg-primary mb-1"
          style={{
            height: "42px",
            width: "42px",
            borderRadius: "50%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url('https://avatar.iran.liara.run/public')`,
          }}
        ></div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="fw-bolder" style={{ fontSize: "13px" }}>
          {userData.name}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="fw-bolder" style={{ fontSize: "10px",textAlign: "center" }}>
          {userData.village}&nbsp;{userData.municipality}<br></br>{userData.city}&nbsp;{userData.state}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="fw-bolder" style={{ fontSize: "12px" }}>
          {userData.country}
        </div>
      </div>
    </div>
  );
};

export default SideBarProfile;
