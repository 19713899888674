import React from "react";
import Slider from "react-slick";
import "./LocationImageSlider.css";

import { useTranslation } from "react-i18next";

const LocationImageSlider = () => {
  const { t, i18n } = useTranslation();

  var settingsH = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex flex-column flex-grow-1">
        <div className="row g-0 flex-grow-1">
          <div
            className="col"
            style={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            <Slider {...settingsH}>
              <div>
                <div className="row g-2 px-1">
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row g-2 px-1">
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      className="w-100 bg-primary"
                      style={{ height: "60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/map1.jpg')", }}
                    ></div>
                  </div>
                </div>
              </div>
              
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationImageSlider;
