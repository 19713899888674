import React from "react";
const Logo = ({width="200px"}) => {
  return (
    <div style={{width:`${width}`}}>
      <img className="img-fluid MainLogo" src="./logomain.png" alt="Gizmap"  />
    </div>
  );
};

export default Logo;
