import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { useTranslation } from "react-i18next";

import BaseLayout from "../../_layout/BaseLayout";
import MainLogo from "../../../global/logo/MainLogo";
import LangSelector from "./LangSelector";

import HelperServices from "../../../../services/HelperServices";


const Home = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [userData, setUserData] = useState({
    name: "",
    type: "",
    latitude: "",
    longitude: "",
    accuracy: "",
  });

  const handleDelStorage = () => {
    localStorage.removeItem("userData");
    console.log("User data removed from localStorage.");
  };

  useEffect(() => {
    // Check if user JSON exists in localStorage
    const userJson = localStorage.getItem("userData");
    // If user JSON exists, parse it and set it to state
    if (userJson) {
      var user = JSON.parse(userJson);
      setUserData(user);
      i18n.changeLanguage(user.lang);
      setTimeout(() => {
        navigate("/gallery");                        
      },1000);

    } else {
      //request location permission
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted" || result.state === "prompt") {
              var locationOptions = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
              };

              const locationSuccess = (pos) => {
                var crd = pos.coords;
                var lat = crd.latitude;
                var lng = crd.longitude;

                const apiKey = "fa512017fcef4982a6aa41d947662f76";
                const url = `https://api.opencagedata.com/geocode/v1/json?q=${lat}%2C${lng}&key=${apiKey}`;

                const getLocation = async () => {
                  try {
                    const res = await axios.get(url);
                    if (res.status === 200) {
                      const apiData = {
                        country: res.data.results[0].components.country,
                        countryCode:
                          res.data.results[0].components.country_code,
                        phoneCode: res.data.results[0].annotations.callingcode,
                        city: res.data.results[0].components.county,
                        zip: res.data.results[0].components.postcode,
                        state: res.data.results[0].components.state,
                        village: res.data.results[0].components.village,
                        municipality:
                          res.data.results[0].components.municipality,
                        currency:
                          res.data.results[0].annotations.currency.iso_code,
                        currencySymbol:
                          res.data.results[0].annotations.currency.symbol,
                        timezone: res.data.results[0].annotations.timezone.name,
                        mapUrl: res.data.results[0].annotations.OSM.url,
                      };

                      var language = "English";
                      var country = apiData.country; 
                      if(country==='Indonesia'){
                        language = "Bahasa";
                      }else if(country==='India'){
                        language = "Hindi";
                      }else if(country==='Spain'){
                        language = "Spanish";
                      }else if(country==='Portugal'){
                        language = "Portuguese";
                      }else{
                        language = "English";
                      }

                      var userId = HelperServices.generateRandomString();

                      const userInfo = {
                        id: userId,
                        name: "Guest",
                        type: "public",
                        latitude: crd.latitude,
                        longitude: crd.longitude,
                        accuracy: crd.accuracy,
                        lang: language,
                        country: apiData.country,
                        countryCode: apiData.countryCode,
                        phoneCode: apiData.phoneCode,
                        phone: '',
                        city: apiData.city,
                        zip: apiData.zip,
                        state: apiData.state,
                        village: apiData.village,
                        municipality: apiData.municipality,
                        currency: apiData.currency,
                        currencySymbol: apiData.currencySymbol,
                        timezone: apiData.timezone,
                        mapUrl: apiData.mapUrl,
                        isLogin: false,
                      };
                      setUserData(userInfo);
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userInfo)
                      );

                      i18n.changeLanguage(language);
                      setTimeout(() => {
                        navigate("/gallery");                        
                      },1000);

                    }
                  } catch (error) {
                    console.error("Error fetching geo-location:", error);
                  }
                };

                getLocation();
              };

              const locationErrors = (err) => {
                console.log(`ERROR(${err.code}): ${err.message}`);
              };

              navigator.geolocation.getCurrentPosition(
                locationSuccess,
                locationErrors,
                locationOptions
              );

            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
              toast.info("Harap aktifkan perizinan lokasi browser Anda", {
                theme: "dark",
                position: "bottom-center",
                transition: Zoom,
              });
            }
          });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  return (
    <BaseLayout>
      <div className="d-flex justify-content-center align-items-center w-100 h-100 bg-primary">
        <div className="d-flex flex-column">
          <MainLogo />
          <LangSelector />
          {/* <div className="mt-3 d-flex justify-content-center  w-100">
            <button className="btn btn-secondary " onClick={handleDelStorage}>Delete</button>
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </BaseLayout>
  );
};

export default Home;
