import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import "./PodCastSlider.css";

const PodCastSlider = () => {
  const navigate = useNavigate();
  const handlePodcastClick = () => {
    navigate("/podcast");
  }

  return (
    <>
      <div className="d-flex mt-3 px-2 mx-1">
        <div style={{ fontSize: "15px", fontWeight: "600" }}>
          <span className="text-primary">Gizmap</span> PodCast
        </div>
      </div>

      <div className="container-fluid mt-2">

      <Swiper className="podCastSwiper">
        <SwiperSlide className="pdCastSlide">
            <div className="row g-2">
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide className="pdCastSlide">
            <div className="row g-2">
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
                <div className="col">
                    <div onClick={handlePodcastClick} className="bg-primary" style={{width:"100%",height:"60px",backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: "url('/assets/upload/podcast1.png')",}}></div>
                </div>
            </div>
        </SwiperSlide>
        
        
      </Swiper>
            
      </div>

    </>
  );
};
export default PodCastSlider;
