import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import PublicLayout from "../../_layout/PublicLayout";
import PanelInfo from "../info/PanelInfo";
import NewsSlider from "./NewsSlider";


const News = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div
          className="d-flex flex-column flex-grow-1 w-100"
          style={{ overflowY: "hide", overflowX: "hidden" }}
        >
          <Swiper
            direction={"vertical"}
            pagination={false}
            modules={[Pagination]}
            className="gallerySwiper w-100"
          >
            <SwiperSlide>
              <div className="d-flex flex-column h-100">
                <PanelInfo />
                <NewsSlider />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex flex-column h-100">
                <PanelInfo />
                <NewsSlider />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex flex-column h-100">
                <PanelInfo />
                <NewsSlider />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </PublicLayout>
  );
};

export default News;
