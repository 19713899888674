import React from "react";
import { useNavigate } from "react-router-dom";

const ImageBox = ({ backgroundImage }) => {
  const navigate = useNavigate();
  const handledClick = () => {
    navigate("/gallery/detail");
  };
  return (
    <div className="bg-white p-1" style={{ aspectRatio: "1 / 1" }}>
      <div
        className="d-flex justify-content-center align-items-center w-100 h-100"
        onClick={handledClick}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url('${backgroundImage}')` ,
        }}
      ></div>
    </div>
  );
};

export default ImageBox;