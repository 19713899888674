import React from "react";
import "../_partials/ButtonMenuHeaderPublic.css";
const ButtonMenuHeaderPublic = ({ onToggleDrawer }) => {
  return (
    <div className="ButtonMenuHeaderPublicContainer">
      <div
        className="ButtonMenuHeaderPublic bg-primary text-white d-flex justify-content-center align-items-center"
        onClick={onToggleDrawer}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="32"
          height="32"
          viewBox="0 0 72 72"
          fill="currentColor"
        >
          <path
            className="icon-path-menu"
            d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default ButtonMenuHeaderPublic;
