import React from "react";
import PublicLayout from "../../_layout/PublicLayout";
import PanelInfo from "../info/PanelInfo";
import ButtonSharePublic from "../../_partials/ButtonSharePublic";
const NewsDetail = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div
          className="d-flex flex-column flex-grow-1 w-100"
          style={{ overflowY: "hide", overflowX: "hidden" }}
        >
          <PanelInfo />

          <div className="row g-0 py-2 align-items-center">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between px-3 py-1">
                <div
                  className="fw-bold PanelSliderText"
                  style={{ fontSize: "13px" }}
                >
                  Konten & Info
                  
                  
                </div>
                <div style={{ fontSize: "13px", fontWeight: "600" }}>
                  Komentar ( 4 )
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col">
              <div
                className=" bg-primary"
                style={{
                  aspectRatio: "1 / 1",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/konten2.jpeg')",
                }}
              ></div>
            </div>
          </div>

          <ButtonSharePublic />
        </div>
      </div>
    </PublicLayout>
  );
};

export default NewsDetail;
