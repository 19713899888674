import React from 'react';
import { useNavigate } from "react-router-dom";
import '../_partials/LogoHeaderPublic.css';
const LogoHeaderPublic = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/gallery");
  };
  return (
    <div className="LogoHeaderPublicContainer">
        <img className="img-fluid LogoHeaderPublic" src="/logomain.png" alt="Gizmap" onClick={handleClick} />
    </div>
  );
};

export default LogoHeaderPublic;
