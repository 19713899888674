import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./Inbox.css";

const Inbox = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer px-2">
        <div className="d-flex justify-content-between align-items-center w-100 mt-2">
          <div className="d-flex align-items-center">
            <div className="fs-1 text-trinary">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="h3 ms-2 fw-bolder" style={{ paddingTop: "11px" }}>
              Messages
            </div>
          </div>
          <div className="d-flex align-items-center text-trinary pe-1">
            <svg
              className="pt-1"
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div
          className="w-100 d-flex flex-grow-1 mb-2"
          style={{ overflowY: "auto" }}
        >
          <div className="d-flex flex-column py-3 px-2 w-100 messageContainer">

            <div className="d-flex justify-content-between w-100 messageItem mb-3">
              <div className="d-flex">
                <div
                  className="bg-primary "
                  style={{
                    height: "36px",
                    width: "36px",
                    borderRadius: "50%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: "url('https://i.pravatar.cc/120')",
                  }}
                ></div>
                <div className="d-flex flex-column ms-3">
                  <div className="fw-bold" style={{ fontSize: "12px" }}>
                    Message Subject
                  </div>
                  <div
                    className="text-truncate"
                    style={{
                      fontSize: "11px",
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    lorem ipsum dolor sit amet lorem ipsum dolor sit amet ....
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ fontSize: "9px" }}
              >
                Mon, 13 May 2024
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 messageItem mb-3">
              <div className="d-flex">
                <div
                  className="bg-primary "
                  style={{
                    height: "36px",
                    width: "36px",
                    borderRadius: "50%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: "url('https://i.pravatar.cc/120')",
                  }}
                ></div>
                <div className="d-flex flex-column ms-3">
                  <div className="fw-bold" style={{ fontSize: "12px" }}>
                    Message Subject
                  </div>
                  <div
                    className="text-truncate"
                    style={{
                      fontSize: "11px",
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    lorem ipsum dolor sit amet lorem ipsum dolor sit amet ....
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ fontSize: "9px" }}
              >
                Mon, 13 May 2024
              </div>
            </div>


          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Inbox;
