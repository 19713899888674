import React from "react";
import PublicLayout from "../../_layout/PublicLayout";
import PanelInfo from "../info/PanelInfo";
import ButtonSharePublic from "../../_partials/ButtonSharePublic";
const PromoDetail = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div
          className="d-flex flex-column flex-grow-1 w-100"
          style={{ overflowY: "hide", overflowX: "hidden" }}
        >
          <PanelInfo />

          <div className="row g-0 py-2 align-items-center">
            <div className="col">
              <div className="d-flex align-items-center justify-content-between px-3 py-1">
                <div
                  className="fw-bold PanelSliderText text-quartery"
                  style={{ fontSize: "13px" }}
                >
                  Promo Diskon Hari Ini
                  <span className="ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-gift mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z" />
                    </svg>
                  </span>
                  
                </div>
                <div style={{ fontSize: "13px", fontWeight: "600" }}>
                  Komentar ( 4 )
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col">
              <div
                className=" bg-primary"
                style={{
                  aspectRatio: "1 / 1",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/diskon1.jpg')",
                }}
              ></div>
            </div>
          </div>

          <ButtonSharePublic />
        </div>
      </div>
    </PublicLayout>
  );
};

export default PromoDetail;
