import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'Bahasa',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      English: {
        translation: {
         welcome: "Welcome to Gizmap",
         kirimPesan: "Send Message",
         petaLokasi: "Location Map",
         waPesan: "WA Message",
         galeri: "Gallery",
         promoDiskon: "Discount Promotion",
         kontenInfo: "Content & Info",
        }
      },
      Bahasa: {
        translation: {
         welcome: "Selamat datang di Gizmap",
         kirimPesan: "Kirim Pesan",
         petaLokasi: "Peta Lokasi",
         waPesan: "WA Pesan",
         galeri: "Galeri",
         promoDiskon: "Promo Diskon",
         kontenInfo: "Konten & Info",
        }
      },
      Hindi: {
        translation: {
         welcome: "गिजमैप में आपका स्वागत है",
         kirimPesan: "संदेश भेजें",
         petaLokasi: "स्थान मानचित्र",
         waPesan: "व्हाट्सएप संदेश",
         galeri: "गैलरी",
         promoDiskon: "छूट प्रोमोशन",
         kontenInfo: "सामग्री और सूचना",
        }
      },
      Spanish: {
        translation: {
         welcome: "Bienvenido a Gizmap",
         kirimPesan: "Enviar Mensaje",
         petaLokasi: "Mapa de Ubicación",
         waPesan: "Mensaje de WA",
         galeri: "Galería",
         promoDiskon: "Promoción de Descuento",
         kontenInfo: "Contenido e Info",
        }
      },
      Portuguese: {
        translation: {
         welcome: "Bem-vindo ao Gizmap",
         kirimPesan: "Enviar Mensagem",
         petaLokasi: "Mapa de Localização",
         waPesan: "Mensagem do WA",
         galeri: "Galeria",
         promoDiskon: "Promoção de Desconto",
         kontenInfo: "Conteúdo e Info",
        }
      },
    }
  });

export default i18n;