import axios from 'axios';
import GlobalServices from './GlobalServices';

class AuthServices {

    static async register(data) {        
        try {
            const response = await axios.post(`${GlobalServices.apiUrl}register`, data);
            return response.data;
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }

    static async sigIn(data) {        
        try {
            const response = await axios.post(`${GlobalServices.apiUrl}login`, data);
            return response.data;
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }

    static async signOut(token, lang) {        
        try {

            const data = {
                lang: lang,
            }
            const response = await axios.post(`${GlobalServices.apiUrl}logout`,data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
            
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }

    static async show(userId, token, lang) {        
        try {

            const data = {
                lang: lang,
            }
            const response = await axios.post(`${GlobalServices.apiUrl}auth/show`,data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
            
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    }

}

export default AuthServices;