import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PanelInfo = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleMapClick = () => {
    navigate("/location");
  }
  return (
    <div className="row g-0">
      <div className="col-8">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="d-flex justify-content-center px-2 pt-3 text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-shop"
                viewBox="0 0 16 16"
              >
                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
              </svg>
            </div>
            <div>
              <div className="d-flex flex-column p-2">
                <div className="fw-bold" style={{ fontSize: "14px" }}>
                  iKlinik Gadget Depok
                </div>
                <div style={{ fontSize: "12px", fontWeight: "600" }}>
                  Jl Raya Ahmad Yani 32 Depok Selatan Jawa Barat 6567
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around align-items-center">
            <button
              className="btn btn-outline-secondary fw-bold px-1 pt-1 pb-1"
              style={{
                height: "auto",
                fontSize: "8px",
                borderRadius: "3px",
              }}
            >
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={14}
                  height={14}
                  fill="currentColor"
                  className="bi bi-envelope text-primary"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
                <p className="p-0 m-0 ms-1" style={{ lineHeight: "9px" }}>
                  {t("kirimPesan")}
                </p>
              </div>
            </button>
            <button
              onClick={handleMapClick}
              className="btn btn-outline-secondary fw-bold px-1 pt-1 pb-1"
              style={{
                height: "auto",
                fontSize: "9px",
                borderRadius: "3px",
              }}
            >
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={14}
                  height={14}
                  fill="currentColor"
                  className="bi bi-map text-danger"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"
                  />
                </svg>
                <p className="p-0 m-0 ms-1" style={{ lineHeight: "9px" }}>
                  {t("petaLokasi")}
                </p>
              </div>
            </button>
            <button
              className="btn btn-outline-secondary fw-bold px-1 pt-1 pb-1"
              style={{
                height: "auto",
                fontSize: "9px",
                borderRadius: "3px",
              }}
            >
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={14}
                  height={14}
                  fill="currentColor"
                  className="bi bi-whatsapp text-primary"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
                <p className="p-0 m-0 ms-1" style={{ lineHeight: "9px" }}>
                  {t("waPesan")}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex flex-column px-3 pt-3">
          <div
            className="bg-primary mb-1"
            style={{
              height: "42px",
              width: "42px",
              borderRadius: "50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: "url('https://i.pravatar.cc/120')",
            }}
          ></div>
          <div className="fw-bold" style={{ fontSize: "12px" }}>
            GizMap! ID
          </div>
          <div className="fw-bold text-primary" style={{ fontSize: "12px" }}>
            iklinikdepok
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelInfo;
