import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import BaseLayout from "../../_layout/BaseLayout";
import "./Join.css";

import Logo from "../../../global/logo/Logo";
import AuthServices from "../../../../services/AuthServices";

const Join = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ phone: "", phoneCode: 0 });
  const nameInputRef = useRef(null);

  useEffect(() => {
    const userJson = localStorage.getItem("userData");
    if (userJson) {
      try {
        const user = JSON.parse(userJson);
        setUserData((prevUserData) => ({
          ...prevUserData,
          ...user,
          phone: `+${user.phoneCode}`,
          phone_code: user.phoneCode,
          country_code: user.countryCode,
          map_url: user.mapUrl,
          lat: user.latitude,
          lng: user.longitude,
        }));
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }

    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const handlePhoneChange = (e) => {
    let phone = e.target.value;
    // Sanitize phone code to remove duplicate '+' signs
    phone = phone.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-'+' characters
    phone = phone.startsWith("+") ? "+" + phone.replace(/\+/g, "") : phone;

    setUserData({ ...userData, phone: phone });
  };
  const handleEmailChange = (e) => {
    setUserData({ ...userData, email: e.target.value });
  };
  const handleNameChange = (e) => {
    setUserData({ ...userData, name: e.target.value });
  };
  const handlePasswordChange = (e) => {
    setUserData({ ...userData, password: e.target.value });
  };
  const handlePassword2Change = (e) => {
    setUserData({ ...userData, password2: e.target.value });
  };

  const handleDaftarClick = async () => {
    var password = userData.password;
    var password2 = userData.password2;
    if (password !== password2) {
      toast.info("Konfirmasi password tidak sama", {
        theme: "dark",
        position: "bottom-center",
        transition: Zoom,
      });
    }

    try {
      let res = await AuthServices.register(userData);
      if (res.code === 200) {
        toast.success(res.message, {
          theme: "dark",
          position: "bottom-center",
          transition: Zoom,
        });

        const userInfo = {
          id: res.id,
          name: userData.name,
          type: "member",
          email: userData.email,
          phone: userData.phone,
          password: '',
          password2: '',          
        };

        setUserData((prevUserData) => ({
          ...prevUserData,
          ...userInfo,
        }));

        localStorage.setItem(
          "userData",
          JSON.stringify(userInfo)
        );

        setTimeout(() => {
          navigate("/login");                        
        },1000);

      } else {
        toast.error(res.message, {
          theme: "dark",
          position: "bottom-center",
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error("An error occurred during registration", {
        theme: "dark",
        position: "bottom-center",
        transition: Zoom,
      });
    }
  };

  return (
    <BaseLayout>
      <div className="d-flex flex-column w-100 h-100 bg-primary">
        <div className="d-flex justify-content-center mt-4 mb-2">
          <Logo width="200px" />
        </div>

        <div className="d-flex px-5">
          <p
            className="text-white"
            style={{ fontSize: "11px", fontWeight: "500" }}
          >
            Bergabung bersama kami, publikasikan layanan Anda lebih dekat dengan
            pelanggan Anda. Sudah mempunyai Akun ? Silahkan{" "}
            <a className="text-white ms-1" href="/login">
              Login
            </a>
          </p>
        </div>

        <input type="hidden" className="phoneCode" value={userData.phoneCode} />

        <div className="d-flex flex-column px-5">
          <div
            className="text-white mb-1"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Nama Toko / Layanan
          </div>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="masukkan nama toko / layanan Anda"
            style={{ fontSize: "12px" }}
            onChange={handleNameChange}
            ref={nameInputRef}
          />
          <div
            className="text-white mb-1"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Email
          </div>
          <input
            type="email"
            className="form-control mb-2"
            placeholder="masukkan email"
            style={{ fontSize: "12px" }}
            onChange={handleEmailChange}
          />
          <div
            className="text-white mb-1"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            No.Telpon
          </div>
          <input
            type="text"
            className="form-control mb-2"
            style={{ fontSize: "12px" }}
            value={userData.phone || ""}
            onChange={handlePhoneChange}
          />

          <div className="">
            <div className="row g-1 mb-2">
              <div className="col">
                <div className="d-flex flex-column">
                  <div
                    className="text-white mb-1"
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  >
                    Password
                  </div>
                  <input
                    type="password"
                    className="form-control mb-2"
                    placeholder="masukkan password"
                    style={{ fontSize: "12px" }}
                    onChange={handlePasswordChange}
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div
                    className="text-white mb-1"
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  >
                    Ulangi Password
                  </div>
                  <input
                    type="password"
                    className="form-control mb-2"
                    placeholder="masukkan password"
                    style={{ fontSize: "12px" }}
                    onChange={handlePassword2Change}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-grid gap-2 mb-3">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleDaftarClick}
            >
              Daftar
            </button>
          </div>

          <div
            className="mt-1"
            style={{ height: "1px", borderTop: "1px solid white" }}
          ></div>

          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "-10px" }}
          >
            <div
              className="d-flex justify-content-center bg-primary text-white"
              style={{ fontSize: "11px", width: "60px" }}
            >
              atau
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="login-with-google-btn mt-3"
                style={{ width: "216px" }}
              >
                Sign in with Google
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a href="#!" className="fb connect">
                Sign in with Facebook
              </a>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <a
                href="/"
                className="text-white"
                style={{
                  textDecoration: "none",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {" "}
                <span className="me-2">
                  <i className="bi bi-arrow-left"></i>
                </span>
                Kembali
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </BaseLayout>
  );
};

export default Join;
