import React from "react";
import { useLocation } from "react-router-dom";

import PublicLayout from "../../_layout/PublicLayout";
import "./UserProfile.css";

import GuestProfile from "./GuestProfile";
import MemberProfile from "./MemberProfile";
import MemberKeyProfile from "./MemberKeyProfile";

const UserProfile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const q = searchParams.get("q");

  const renderProfile = () => {
    if (type === "public") {
      return <GuestProfile />;
    } else if (type === "member") {
      return <MemberProfile />;
    } else if (type === "memberkey") {
        return <MemberProfile />;
      } else {
      return <p>Invalid user type</p>;
    }
  };

  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div className="w-100 h-100">
        {renderProfile()}
        </div>
      </div>
    </PublicLayout>
  );
};

export default UserProfile;
