import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const SliderMenuTab = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const handleGalleryClick = () => {
        navigate("/gallery");
    }
    const handlePromoClick = () => {
        navigate("/promo");
    }
    const handleNewsClick = () => {
        navigate("/news");
    }

  return (
    <div className="row g-0 py-2 align-items-center">
          <div className="col">
            <div className="d-flex align-items-center justify-content-center" onClick={handleGalleryClick}>
              <div className="fw-bold PanelSliderText">{t("galeri")} (45)</div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center justify-content-center text-danger" onClick={handlePromoClick}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-gift mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z" />
                </svg>
              </div>
              <div className="fw-bold PanelSliderText ms-1">
              {t("promoDiskon")} (25)
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center justify-content-center" onClick={handleNewsClick}>
              <div className="fw-bold PanelSliderText text-trinary pe-1">
              {t("kontenInfo")} (145)
              </div>
            </div>
          </div>
        </div>
  );
};

export default SliderMenuTab;
