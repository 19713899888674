import React from "react";
import "../_partials/SearchHeaderPublic.css";
const SearchHeaderPublic = () => {
  return (
    <div className="mx-2">
      <div className="input-group" style={{height:"35px"}}>
        <input
          type="text"
          className="form-control border-end-0 fs-6 ps-2 pe-1 py-1 SearchInputHeaderPublic"
          placeholder="Cari Toko atau Merk"
          style={{height:"35px"}}
        />
        <span className="input-group-text input-group-text-right bg-white ps-1 pe-2" id="basic-addon2" style={{height:"35px"}}>
          <i className="bi bi-search"></i>
        </span>
      </div>
    </div>
  );
};

export default SearchHeaderPublic;
