import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./Notification.css";

const Notification = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer px-2">
        <div className="d-flex justify-content-between align-items-center w-100 mt-2">
          <div className="d-flex align-items-center">
            <div className="fs-1 text-trinary">
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.02 2.90991C8.70997 2.90991 6.01997 5.59991 6.01997 8.90991V11.7999C6.01997 12.4099 5.75997 13.3399 5.44997 13.8599L4.29997 15.7699C3.58997 16.9499 4.07997 18.2599 5.37997 18.6999C9.68997 20.1399 14.34 20.1399 18.65 18.6999C19.86 18.2999 20.39 16.8699 19.73 15.7699L18.58 13.8599C18.28 13.3399 18.02 12.4099 18.02 11.7999V8.90991C18.02 5.60991 15.32 2.90991 12.02 2.90991Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                />
                <path
                  d="M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                />
              </svg>
            </div>
            <div className="h3 ms-2 fw-bolder" style={{ paddingTop: "11px" }}>
              Notification
            </div>
          </div>
        </div>

        <div
          className="w-100 d-flex flex-grow-1 mb-2"
          style={{ overflowY: "auto" }}
        >
          <div className="d-flex flex-column py-3 px-2 w-100 notificationContainer">

            <div className="d-flex justify-content-between w-100 notificationItem mb-3">
              <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-center align-items-center bg-primary flex-shrink-0"
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </div>
                <div className="d-flex flex-column ms-3">
                  <div className="fw-bold" style={{ fontSize: "12px" }}>
                    Notification Subject
                  </div>
                  <div
                    className=""
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                    ipsum dolor
                  </div>
                  <div
                    className="d-flex align-items-center mt-1 text-trinary"
                    style={{ fontSize: "9px" }}
                  >
                    Mon, 13 May 2024
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between w-100 notificationItem mb-3">
              <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-center align-items-center bg-primary flex-shrink-0"
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                    }}
                  ></div>
                </div>
                <div className="d-flex flex-column ms-3">
                  <div className="fw-bold" style={{ fontSize: "12px" }}>
                    Notification Subject
                  </div>
                  <div
                    className=""
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem
                    ipsum dolor
                  </div>
                  <div
                    className="d-flex align-items-center mt-1 text-trinary"
                    style={{ fontSize: "9px" }}
                  >
                    Mon, 13 May 2024
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Notification;
