import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./PublicLayout.css";
import HeaderPublic from "../_partials/HeaderPublic";
import FooterNavMobile from "../_partials/FooterNavMobile";
import SideBarProfile from "../_partials/SideBarProfile";
import AuthServices from "../../../services/AuthServices";
import HelperServices from "../../../services/HelperServices";

const PublicLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("Home");
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loadingLogout, setLoadingLogout] = useState(false);

  useEffect(() => {
    const userJson = localStorage.getItem("userData");
    if (userJson) {
      var user = JSON.parse(userJson);

      setUserData((prevUserData) => ({
        ...prevUserData,
        ...user,
      }));

      if (user.type === "member") {
        setIsAuth(true);
      }
    }

    const strUrl = window.location.pathname.replace("/", "").toUpperCase();
    setActiveItem(strUrl);
  }, []);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = async (menuItem) => {
    setActiveItem(menuItem);
    switch (menuItem) {
      case "HOME":
        navigate("/home");
        break;
      case "GALLERY":
        navigate("/gallery");
        break;
      case "PROMO":
        navigate("/promo");
        break;
      case "NEWS":
        navigate("/news");
        break;
      case "SUGGESTION":
        navigate("/suggestion");
        break;
      case "LANG":
        navigate("/");
        break;
      case "JOIN":
        navigate("/join");
        break;
      case "LOGIN":
        navigate("/login");
        break;
      case "PODCAST":
        navigate("/podcast");
        break;
      case "LOGOUT":

        setLoadingLogout(true);

        let res = await AuthServices.signOut(
          userData.accessToken,
          userData.lang
        );

        if (res.code === 200) {
          toast.success(res.message, {
            theme: "dark",
            position: "bottom-center",
            transition: Zoom,
          });

          var userId = HelperServices.generateRandomString();

          const userInfo = {
            id: userId,
            name: "Guest",
            type: "public",
            latitude: userData.lat,
            longitude: userData.lng,
            lang: userData.lang,
            country: userData.country,
            countryCode: userData.country_code,
            phoneCode: userData.phone_code,
            phone: "",
            city: userData.city,
            zip: userData.zip,
            state: userData.state,
            village: userData.village,
            municipality: userData.municipality,
            currency: userData.currency ? userData.currency : "",
            currencySymbol: userData.currency_symbol
              ? userData.currency_symbol
              : "",
            timezone: userData.timezone ? userData.timezone : "",
            mapUrl: userData.map_url,
            isLogin: false,
            accessToken: "",
          };
          setUserData(userInfo);
          localStorage.setItem("userData", JSON.stringify(userInfo));
        } else {

        }

        setTimeout(() => {
          setLoadingLogout(false);
          navigate("/gallery");
        }, 1000);


        break;
      default:
    }
  };

  return (
    <>
      <HeaderPublic onToggleDrawer={toggleDrawer} />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        zIndex={1035}
        className="bla bla bla"
      >
        <div
          className="d-flex flex-column h-100 w-100 p-3"
          style={{ overflowY: "auto" }}
        >
          <div className="d-flex w-100 h-100">
            <div className="d-flex flex-column w-100">
              <SideBarProfile />

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "HOME" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("HOME")}
              >
                <div className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 64 64"
                    fill="currentColor"
                  >
                    <path d="M 32 8 C 31.08875 8 30.178047 8.3091875 29.435547 8.9296875 L 8.8007812 26.171875 C 8.0357812 26.810875 7.7634844 27.925203 8.2714844 28.783203 C 8.9184844 29.875203 10.35025 30.088547 11.28125 29.310547 L 12 28.710938 L 12 47 C 12 49.761 14.239 52 17 52 L 47 52 C 49.761 52 52 49.761 52 47 L 52 28.712891 L 52.71875 29.3125 C 53.09275 29.6255 53.546047 29.777344 53.998047 29.777344 C 54.693047 29.777344 55.382672 29.416656 55.763672 28.722656 C 56.228672 27.874656 55.954891 26.803594 55.212891 26.183594 L 52 23.498047 L 52 15 C 52 13.895 51.105 13 50 13 L 48 13 C 46.895 13 46 13.895 46 15 L 46 18.484375 L 34.564453 8.9296875 C 33.821953 8.3091875 32.91125 8 32 8 z M 32 12.152344 C 32.11475 12.152344 32.228766 12.191531 32.322266 12.269531 L 48 25.369141 L 48 46 C 48 47.105 47.105 48 46 48 L 38 48 L 38 34 C 38 32.895 37.105 32 36 32 L 28 32 C 26.895 32 26 32.895 26 34 L 26 48 L 18 48 C 16.895 48 16 47.105 16 46 L 16 25.367188 L 31.677734 12.269531 C 31.771234 12.191531 31.88525 12.152344 32 12.152344 z"></path>
                  </svg>
                </div>
                <div className="fw-bold">Home</div>
              </div>

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "GALLERY" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("GALLERY")}
              >
                <div className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 64 64"
                    fill="currentColor"
                  >
                    <path d="M 15 13 C 11.686 13 9 15.686 9 19 L 9 45 C 9 48.314 11.686 51 15 51 L 49 51 C 52.314 51 55 48.314 55 45 L 55 19 C 55 15.686 52.314 13 49 13 L 15 13 z M 15 17 L 49 17 C 50.105 17 51 17.895 51 19 L 51 41.433594 L 41.681641 33.054688 C 40.158641 31.684688 37.844219 31.686547 36.324219 33.060547 L 28.402344 40.21875 L 24.609375 36.974609 C 23.108375 35.690609 20.894437 35.695375 19.398438 36.984375 L 13 42.496094 L 13 19 C 13 17.895 13.895 17 15 17 z M 23 23 C 20.791 23 19 24.791 19 27 C 19 29.209 20.791 31 23 31 C 25.209 31 27 29.209 27 27 C 27 24.791 25.209 23 23 23 z"></path>
                  </svg>
                </div>
                <div className="fw-bold">Gallery</div>
              </div>

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "PROMO" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("PROMO")}
              >
                <div className="me-2">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 8.99998C10.5523 8.99998 11 9.44769 11 9.99998C11 10.5523 10.5523 11 10 11C9.44775 11 9.00004 10.5523 9.00004 9.99998C9.00004 9.44769 9.44775 8.99998 10 8.99998Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13 14C13 14.5523 13.4478 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13C13.4478 13 13 13.4477 13 14Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.7071 14.7071L14.7071 10.7071C15.0977 10.3166 15.0977 9.6834 14.7071 9.29287C14.3166 8.90235 13.6835 8.90235 13.2929 9.29287L9.29293 13.2929C8.90241 13.6834 8.90241 14.3166 9.29293 14.7071C9.68346 15.0976 10.3166 15.0976 10.7071 14.7071Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.3117 4.07145L15.1708 4.34503L14.5575 3.34485C13.3869 1.43575 10.6131 1.43575 9.44254 3.34485L8.82926 4.34503L7.68836 4.07145C5.51069 3.54925 3.54931 5.51063 4.07151 7.6883L4.34509 8.8292L3.34491 9.44248C1.43581 10.6131 1.43581 13.3869 3.34491 14.5575L4.34509 15.1708L4.07151 16.3117C3.54931 18.4893 5.51069 20.4507 7.68836 19.9285L8.82926 19.6549L9.44254 20.6551C10.6131 22.5642 13.3869 22.5642 14.5575 20.6551L15.1708 19.6549L16.3117 19.9285C18.4894 20.4507 20.4508 18.4893 19.9286 16.3117L19.655 15.1708L20.6552 14.5575C22.5643 13.3869 22.5643 10.6131 20.6552 9.44248L19.655 8.8292L19.9286 7.6883C20.4508 5.51063 18.4894 3.54925 16.3117 4.07145ZM11.1475 4.3903C11.5377 3.75393 12.4623 3.75393 12.8525 4.3903L13.8454 6.00951C14.0717 6.37867 14.51 6.56019 14.9311 6.45922L16.7781 6.01631C17.504 5.84225 18.1578 6.49604 17.9837 7.22193L17.5408 9.06894C17.4398 9.49003 17.6213 9.92827 17.9905 10.1546L19.6097 11.1475C20.2461 11.5377 20.2461 12.4623 19.6097 12.8525L17.9905 13.8453C17.6213 14.0717 17.4398 14.5099 17.5408 14.931L17.9837 16.778C18.1578 17.5039 17.504 18.1577 16.7781 17.9836L14.9311 17.5407C14.51 17.4398 14.0717 17.6213 13.8454 17.9904L12.8525 19.6097C12.4623 20.246 11.5377 20.246 11.1475 19.6097L10.1547 17.9904C9.92833 17.6213 9.49009 17.4398 9.069 17.5407L7.22199 17.9836C6.4961 18.1577 5.84231 17.5039 6.01637 16.778L6.45928 14.931C6.56026 14.5099 6.37873 14.0717 6.00957 13.8453L4.39036 12.8525C3.75399 12.4623 3.75399 11.5377 4.39036 11.1475L6.00957 10.1546C6.37873 9.92827 6.56026 9.49003 6.45928 9.06894L6.01637 7.22193C5.84231 6.49604 6.4961 5.84225 7.22199 6.01631L9.069 6.45922C9.49009 6.56019 9.92833 6.37867 10.1547 6.00951L11.1475 4.3903Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="fw-bold">Diskon & Promo</div>
              </div>

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "NEWS" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("NEWS")}
              >
                <div className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 64 64"
                  >
                    <path d="M 23 12 C 20.243 12 18 14.243 18 17 L 18 22 L 15 22 C 12.243 22 10 24.243 10 27 L 10 46 C 10 49.309 12.691 52 16 52 L 48 52 C 51.309 52 54 49.309 54 46 L 54 17 C 54 14.243 51.757 12 49 12 L 23 12 z M 23 16 L 49 16 C 49.552 16 50 16.449 50 17 L 50 46 C 50 47.103 49.103 48 48 48 L 21.65625 48 C 21.87825 47.374 22 46.701 22 46 L 22 17 C 22 16.449 22.448 16 23 16 z M 27 20 C 25.896 20 25 20.895 25 22 C 25 23.105 25.896 24 27 24 L 45 24 C 46.104 24 47 23.105 47 22 C 47 20.895 46.104 20 45 20 L 27 20 z M 15 26 L 18 26 L 18 46 C 18 47.103 17.103 48 16 48 C 14.897 48 14 47.103 14 46 L 14 27 C 14 26.449 14.448 26 15 26 z M 27 27 C 25.896 27 25 27.895 25 29 C 25 30.105 25.896 31 27 31 L 45 31 C 46.104 31 47 30.105 47 29 C 47 27.895 46.104 27 45 27 L 27 27 z M 27 34 C 25.895 34 25 34.895 25 36 L 25 43 C 25 44.105 25.895 45 27 45 L 33 45 C 34.105 45 35 44.105 35 43 L 35 36 C 35 34.895 34.105 34 33 34 L 27 34 z M 40 34 C 38.896 34 38 34.895 38 36 C 38 37.105 38.896 38 40 38 L 45 38 C 46.104 38 47 37.105 47 36 C 47 34.895 46.104 34 45 34 L 40 34 z M 40 41 C 38.896 41 38 41.895 38 43 C 38 44.105 38.896 45 40 45 L 45 45 C 46.104 45 47 44.105 47 43 C 47 41.895 46.104 41 45 41 L 40 41 z"></path>
                  </svg>
                </div>
                <div className="fw-bold">Konten & Info</div>
              </div>

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "PODCAST" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("PODCAST")}
              >
                <div className="me-2">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10V13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13V10Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M13 10L15 10"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M13 13L15 13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9 10L10 10"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9 13L10 13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16.9514 8.24993C17.0894 8.64047 17.5179 8.84516 17.9085 8.70713C18.299 8.5691 18.5037 8.1406 18.3656 7.75007L16.9514 8.24993ZM6.74996 14.1538V9.99998H5.24996V14.1538H6.74996ZM5.24996 14.1538C5.24996 14.7592 4.7592 15.25 4.15381 15.25V16.75C5.58762 16.75 6.74996 15.5877 6.74996 14.1538H5.24996ZM17.2499 14.1538C17.2499 15.5877 18.4123 16.75 19.8461 16.75V15.25C19.2407 15.25 18.7499 14.7592 18.7499 14.1538H17.2499ZM11.9999 3.25C8.27203 3.25 5.24996 6.27207 5.24996 9.99998H6.74996C6.74996 7.1005 9.10046 4.75 11.9999 4.75V3.25ZM11.9999 4.75C14.2847 4.75 16.2303 6.20984 16.9514 8.24993L18.3656 7.75007C17.4394 5.1294 14.9401 3.25 11.9999 3.25V4.75Z"
                      fill="currentColor"
                    />
                    <path
                      d="M2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12V14C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14V12Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M18 12C18 10.8954 18.8954 10 20 10C21.1046 10 22 10.8954 22 12V14C22 15.1046 21.1046 16 20 16C18.8954 16 18 15.1046 18 14V12Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M12 16V19"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div className="fw-bold">Podcast</div>
              </div>

              <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "SUGGESTION" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("SUGGESTION")}
              >
                <div className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="4"
                    >
                      <path d="M44 24v16.818C44 42.023 43.105 43 42 43H6c-1.105 0-2-.977-2-2.182V24l20 13l20-13Z" />
                      <path
                        strokeLinecap="round"
                        d="m4 23.784l10-6.892m30 6.892l-10-6.892"
                      />
                      <path d="M34 5H14v24.415a2 2 0 0 0 .91 1.677l8 5.2a2 2 0 0 0 2.18 0l8-5.2a2 2 0 0 0 .91-1.677V5Z" />
                      <path strokeLinecap="round" d="M20 13h4m-4 6h8" />
                    </g>
                  </svg>
                </div>
                <div className="fw-bold">Saran & Masukan</div>
              </div>

              {!isAuth && (
                <div
                  className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                    activeItem === "JOIN" ? "active" : ""
                  }`}
                  onClick={() => handleMenuItemClick("LOGIN")}
                >
                  <div className="me-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 6C8 3.79086 9.79086 2 12 2H17.5C19.9853 2 22 4.01472 22 6.5V17.5C22 19.9853 19.9853 22 17.5 22H12C9.79086 22 8 20.2091 8 18V17C8 16.4477 8.44772 16 9 16C9.55228 16 10 16.4477 10 17V18C10 19.1046 10.8954 20 12 20H17.5C18.8807 20 20 18.8807 20 17.5V6.5C20 5.11929 18.8807 4 17.5 4H12C10.8954 4 10 4.89543 10 6V7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7V6ZM12.2929 8.29289C12.6834 7.90237 13.3166 7.90237 13.7071 8.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L13.7071 15.7071C13.3166 16.0976 12.6834 16.0976 12.2929 15.7071C11.9024 15.3166 11.9024 14.6834 12.2929 14.2929L13.5858 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L13.5858 11L12.2929 9.70711C11.9024 9.31658 11.9024 8.68342 12.2929 8.29289Z"
                        fill="#0F1729"
                      />
                    </svg>
                  </div>
                  <div className="fw-bold">Login</div>
                </div>
              )}

              {isAuth && (
                <div
                  className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                    activeItem === "JOIN" ? "active" : ""
                  }`}
                  onClick={() => handleMenuItemClick("LOGOUT")}
                >
                  <div className="me-2">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 -0.5 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <div className="fw-bold me-2">Logout</div>

                  {loadingLogout && (
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              )}

              {/* <div
                className={`d-flex align-items-center mb-2 p-2 w-100 rounded-3 sideNavItem ${
                  activeItem === "LANG" ? "active" : ""
                }`}
                onClick={() => handleMenuItemClick("LANG")}
              >
                <div className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M15.5 23q-2.6 0-4.587-1.55T8.275 17.5q1.125.05 2.225-.225t2.1-.775h4.1q.025-.275.038-.537t.012-.538q0-.225-.012-.462T16.7 14.5h-1.475q.4-.45.738-.95t.612-1.05H20.1q-.5-.75-1.2-1.312t-1.575-.863q.125-.5.163-1.025t.012-1.025q2.4.65 3.95 2.638T23 15.5q0 3.125-2.187 5.313T15.5 23m-2.375-2.55q-.175-.5-.312-.975t-.238-.975H10.9q.425.625.987 1.125t1.238.825m2.375.35q.3-.55.513-1.125t.362-1.175h-1.75q.15.6.375 1.175t.5 1.125m2.375-.35q.675-.325 1.238-.825T20.1 18.5h-1.675q-.125.5-.25.975t-.3.975m.825-3.95h2.2q.05-.25.075-.488T21 15.5t-.025-.513t-.075-.487h-2.2q.025.225.038.463t.012.462q0 .275-.012.538t-.038.537M8.5 16q-3.125 0-5.313-2.187T1 8.5t2.188-5.312T8.5 1t5.313 2.188T16 8.5t-2.187 5.313T8.5 16m0-2q2.275 0 3.888-1.612T14 8.5t-1.612-3.887T8.5 3T4.613 4.613T3 8.5t1.613 3.888T8.5 14M6 8q.425 0 .713-.288T7 7t-.288-.712T6 6t-.712.288T5 7t.288.713T6 8m2.5 4.4q1.2 0 2.138-.675T12 10H5q.425 1.05 1.363 1.725T8.5 12.4M11 8q.425 0 .713-.288T12 7t-.288-.712T11 6t-.712.288T10 7t.288.713T11 8m-2.5.5"
                    />
                  </svg>
                </div>
                <div className="fw-bold">Pilih Bahasa</div>
              </div> */}
            </div>
          </div>
        </div>
      </Drawer>
      <div className="d-flex flex-column justify-content-center h-100 w-100">
        {children}
      </div>
      <FooterNavMobile />
      <ToastContainer />
    </>
  );
};

export default PublicLayout;
