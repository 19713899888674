import React, { useEffect, useState } from "react";
import "./MainLogo.css";
const MainLogo = () => {
  const [showLogo, setShowLogo] = useState(false);
  useEffect(() => {
    // Delay the animation to ensure the logo is visible when it starts
    const delayAnimation = setTimeout(() => {
      setShowLogo(true);
    }, 100);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(delayAnimation);
  }, []);
  return (
    <div className="d-flex justify-content-center" style={{ marginTop: "130px" }}>
      <div
        className={`col-8 col-md-10 d-flex justify-content-center align-items-center MainLogo ${
          showLogo ? "show" : ""
        }`}
      >
        <img className="img-fluid MainLogo" src="./logomain.png" alt="Gizmap" />
      </div>
    </div>
  );
};

export default MainLogo;
