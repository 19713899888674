import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from 'react-spring';
import { useTranslation } from "react-i18next";
import "./LangSelector.css";

const LangSelector = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(null);
  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const handleLangChange = (event) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
    navigate("/gallery");
  };

  return (
    <animated.div style={{ opacity, marginTop: "100px" }} className={`d-flex justify-content-center `}>
      <div className={`w-100 h-100 `}>
        <div className="mx-auto col-8 col-md-5 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="English"
                checked={selectedLang === "English"}
                onChange={handleLangChange}
              />
              <label
                className="form-check-label langRadiosLabel"
                htmlFor="flexRadioDefault1"
              >
                English
              </label>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="Bahasa"
                checked={selectedLang === "Bahasa"}
                onChange={handleLangChange}
              />
              <label
                className="form-check-label langRadiosLabel"
                htmlFor="flexRadioDefault2"
              >
                Bahasa Indonesia
              </label>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value="Hindi"
                checked={selectedLang === "Hindi"}
                onChange={handleLangChange}

              />
              <label
                className="form-check-label langRadiosLabel"
                htmlFor="flexRadioDefault3"
              >
                Hindi
              </label>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault4"
                value="Spanish"
                checked={selectedLang === "Spanish"}
                onChange={handleLangChange}
              />
              <label
                className="form-check-label langRadiosLabel"
                htmlFor="flexRadioDefault4"
              >
                Spanish
              </label>
            </div>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault5"
                value="Portuguese"
                checked={selectedLang === "Portuguese"}
                onChange={handleLangChange}
              />
              <label
                className="form-check-label langRadiosLabel"
                htmlFor="flexRadioDefault5"
              >
                Portuguese
              </label>
            </div>
          </div>
        </div>
      </div>
      </animated.div>
  );
};

export default LangSelector;
