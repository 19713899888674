import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import "./PromoSlider2.css";

const PromoSlider2 = () => {
  const banner3Url1 = "/assets/upload/promo1.jpg";
  const banner3Url2 = "/assets/upload/promo2.jpg";
  const banner3Url3 = "/assets/upload/promo3.jpg";
  const banner3Url4 = "/assets/upload/promo4.jpg";

  return (
    <>
      <div className="d-flex px-2 mx-1">
        <div style={{ fontSize: "13px", fontWeight: "600" }}>
          Sedang Ada Promo & Diskon Terdekat
        </div>
      </div>

      <div className="d-flex px-2">
        <Swiper
          pagination={false}
          spaceBetween={2}
          modules={[Pagination]}
          className="bannerSwiper3 w-100"
        >
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url1}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smartphone
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iRoom Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 143 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url2}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smart TV
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iService Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 110 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url3}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Console
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iServices Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 74 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url1}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smartphone
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iRoom Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 143 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url2}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Smart TV
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iService Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 110 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="promoSwiperSlide2">
            <div className="container-fluid px-1">
              <div className="row g-0">
                <div className="col">
                  <div
                    className="w-100 bg-primary"
                    style={{
                      aspectRatio: "1/1",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url('${banner3Url3}')`,
                    }}
                  >
                    <div
                      className="w-100 h-100"
                      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                    >
                      <div className="d-flex flex-column w-100 h-100 p-2">
                        <div className="mt-auto d-flex flex-column">
                          <div>
                            <span
                              className="rounded-pill text-center"
                              style={{
                                fontSize: "7px",
                                fontWeight: "600",
                                padding: "2px 4px 2px 4px",
                                backgroundColor: "#3498db",
                                color: "yellow",
                              }}
                            >
                              Repair Console
                            </span>
                          </div>
                          <div
                            className="text-white"
                            style={{ fontSize: "10px", fontWeight: "600" }}
                          >
                            iServices Bandung
                          </div>
                          <div
                            className="d-flex align-items-center p-0 mb-1"
                            style={{ height: "10px" }}
                          >
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>
                            <div className="text-warning p-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                fill="currentColor"
                                className="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            </div>

                            <div
                              className="ms-2 text-white"
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                lineHeight: "12px",
                                paddingTop: "2px",
                              }}
                            >
                              ( 74 )
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};
export default PromoSlider2;
