import React from "react";
import "../_partials/HeaderPublic.css";
import LogoHeaderPublic from "./LogoHeaderPublic";
import SearchHeaderPublic from "./SearchHeaderPublic";
import ButtonMenuHeaderPublic from "./ButtonMenuHeaderPublic";
const HeaderPublic = ({ onToggleDrawer }) => {
  return (
    <div className="fixed-top d-flex headerWrapper mx-auto" >
      <div className="HeaderPublic w-100 d-flex justify-content-between align-items-center px-2" >
        <LogoHeaderPublic />
        <SearchHeaderPublic />
        <ButtonMenuHeaderPublic onToggleDrawer={onToggleDrawer} />
      </div>
    </div>
  );
};

export default HeaderPublic;
