import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconUrl from "./markericon2.png";

import "./GuestProfile.css";

const CustomIcon = L.icon({
  iconUrl: iconUrl,
  iconSize: [42, 42],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const GuestProfile = () => {
  const [userData, setUserData] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapLink, setMapLink] = useState("");

  var latitude = "";
  var longitude = "";
  var mapUrl = `https://www.google.com/maps/embed/v1/view?key=YOUR_GOOGLE_MAPS_API_KEY&center=${latitude},${longitude}&zoom=17`;

  useEffect(() => {
    const userJson = localStorage.getItem("userData");
    if (userJson) {
      var user = JSON.parse(userJson);

      const iframeLink = `https://www.openstreetmap.org/?mlat=${user.latitude}&mlon=${user.longitude}#map=17/${user.latitude}/${user.longitude}`;
      setMapLink(iframeLink);

      const storedLat = user.latitude;
      const storedLng = user.longitude;

      if (storedLat && storedLng) {
        setLat(parseFloat(storedLat));
        setLng(parseFloat(storedLng));
        setMapLoading(false);
      }

      setUserData(user);
      mapUrl = `https://www.google.com/maps/embed/v1/view?key=YOUR_GOOGLE_MAPS_API_KEY&center=${user.latitude},${user.longitude}&zoom=17`;
    }
  }, []);

  return (
    <div
      className="d-flex flex-column w-100 h-100 py-3 px-4 bg-primary"
      style={{ overflowY: "auto" }}
    >
      <div className="d-flex justify-content-center">
        <div
          style={{
            border: "4px solid white",
            borderRadius: "50%",
            height: "80px",
            width: "80px",
          }}
        >
          <div
            className="bg-primary mb-3"
            style={{
              height: "72px",
              width: "72px",
              borderRadius: "50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: `url('https://avatar.iran.liara.run/public')`,
            }}
          ></div>
        </div>
      </div>
      <div className="d-flex flex-column " style={{ fontSize: "14px" }}>
        <div className="mb-1 fw-bold text-white">Nama</div>
        <div
          className="px-3 py-2 rounded mb-2"
          style={{ backgroundColor: "#f1f2f6", fontWeight: "500" }}
        >
          {userData.name}
        </div>
        <div className="mb-1 fw-bold text-white">Lokasi</div>
        <div
          className="px-3 py-2 rounded mb-2"
          style={{ backgroundColor: "#f1f2f6", fontWeight: "500" }}
        >
          {userData.village} {userData.municipality} {userData.city}{" "}
          {userData.state}
          <br></br>
          {userData.country}
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex flex-column w-50">
            <div className="mb-1 fw-bold text-white">Lat</div>
            <div
              className="px-3 py-2 rounded mb-2 me-1"
              style={{ backgroundColor: "#f1f2f6", fontWeight: "500" }}
            >
              {userData.latitude}
            </div>
          </div>
          <div className="d-flex flex-column w-50">
            <div className="mb-1 fw-bold text-white ms-1">Lng</div>
            <div
              className="px-3 py-2 rounded mb-2 ms-1"
              style={{ backgroundColor: "#f1f2f6", fontWeight: "500" }}
            >
              {userData.longitude}
            </div>
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex w-100 bg-primary" style={{ height: "250px" }}>
            <div style={{ height: "100%", width: "100%" }}>

              {mapLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="spinner-grow spinner-grow-sm me-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <MapContainer
                  center={[lat, lng]}
                  zoom={13}
                  style={{ height: "300px" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker
                    position={[userData.latitude, userData.longitude]}
                    icon={CustomIcon}
                  >
                    <Popup>Your location</Popup>
                  </Marker>
                </MapContainer>
              )}
              <br />
              <small>
                <a
                  className="text-decoration-none text-white"
                  target="_blank"
                  href={mapLink}
                >
                  View Larger Map
                </a>
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex my-5 w-100"></div>
      </div>
    </div>
  );
};

export default GuestProfile;
