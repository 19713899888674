import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../_partials/FooterNavMobile.css";

const FooterNavMobile = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const userJson = localStorage.getItem("userData");
    if (userJson) {
      var user = JSON.parse(userJson);
      setUserData(user);
    }
  }, []);

  const handleClickHome = () => {
    navigate("/home");
  };

  const handleClickMessage = () => {
    if (userData.type === "public") {
      navigate("/inbox");
    } else {
      navigate("/inbox");
    }
  };

  const handleClickNotif = () => {
    if (userData.type === "public") {
      navigate("/notification");
    } else {
      navigate("/notification");
    }
  };

  const handleClickLocate = () => {
    if (userData.type === "public") {
      navigate("/login");
    } else {
      navigate("/home");
    }
  };

  const handleClickPost = () => {
    if (userData.type === "public") {
      navigate("/login");
    } else {
      navigate("/home");
    }
  };

  return (
    <div className="fixed-bottom d-flex FooterNavMobileContainer mx-auto">
      <div className="FooterNavMobile w-100 d-flex justify-content-around align-items-center px-2">
        <div className="text-white m-2 navHome" onClick={handleClickHome}>
          <i className="bi bi-house-door fs-1"></i>
        </div>

        <div className="text-white m-2 navLocation" onClick={handleClickLocate}>
          <i className="bi bi-geo-alt fs-1"></i>
        </div>

        <div className="text-white m-2 navLocation" onClick={handleClickPost}>
            {/* <i className="bi bi-folder-plus fs-1"></i> */}

            <svg
              className=""
              width="calc(1.7rem + 1.5vw)"
              height="calc(1.7rem + 1.5vw)"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.5 3V5.5M18.5 8V5.5M18.5 5.5H16M18.5 5.5H21"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

        <div
          className="text-white m-2 navLocation"
          onClick={handleClickMessage}
        >
          <i className="bi bi-envelope fs-1"></i>
        </div>

        <div className="text-white m-2 navLocation" onClick={handleClickNotif}>
          <i className="bi bi-bell fs-1"></i>
        </div>
      </div>
    </div>
  );
};

export default FooterNavMobile;
