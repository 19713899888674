import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';

import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@flaticon/flaticon-uicons/css/all/all.css";

function App() {
  
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;

