import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./PodCast.css";

const PodCast = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div className="px-2 d-flex flex-column px-3 py-2">
          <div
            className="text-trinary"
            style={{ fontWeight: "700", fontSize: "18px" }}
          >
            Gizmap PodCast
          </div>
          <div
            className="text-primary"
            style={{ fontWeight: "700", fontSize: "13px", lineHeight: "15px" }}
          >
            Mengapa Perbaikan Iphone Mahal Dibanding Android Padahal Sama Sama
            Smartphone
          </div>
          <div style={{ fontWeight: "700", fontSize: "11px" }}>
            <span className="text-trinary">3 Mei 2024 -</span>{" "}
            <span className="text-primary">Gizmap Channel</span>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div
              className="rounded"
              style={{
                border: "2px solid var(--primary-color)",
                height: "150px",
                width: "85%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: "url('/assets/upload/podcast1.png')",
              }}
            >
              <div className=" position-relative">
                <div
                  className=" position-absolute"
                  style={{ top: "-15px", right: "-20px" }}
                >
                  <img
                    src="/assets/images/podcastlogo2.png"
                    alt="podcast logo"
                    style={{ width: "80px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn bg-secondary p-1"
              style={{
                fontSize: "10px",
                fontWeight: "600",
                width: "70px",
                lineHeight: "11px",
              }}
            >
              Share <br></br>FB
            </button>
            <button
              className="btn bg-secondary p-1 mx-2"
              style={{
                fontSize: "10px",
                fontWeight: "600",
                width: "70px",
                lineHeight: "11px",
              }}
            >
              Share <br></br>Instagram
            </button>
            <button
              className="btn bg-secondary p-1"
              style={{
                fontSize: "10px",
                fontWeight: "600",
                width: "70px",
                lineHeight: "11px",
              }}
            >
              Share <br></br>Tiktok
            </button>
          </div>

          <div
            className="text-trinary mt-4 mb-2"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Video Lain
          </div>

          <div
            className="d-flex flex-column"
            style={{ maxHeight: "204px", overflowY: "auto" }}
          >
            <div className="d-flex mb-1">
              <div
                className="bg-primary mb-1 d-flex flex-shrink-0"
                style={{
                  height: "60px",
                  width: "120px",
                  border: "2px solid var(--primary-color)",
                  borderRadius: "3px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/podcast1.png')",
                }}
              ></div>
              <div className="d-flex flex-column ms-2">
                <div
                  className=""
                  style={{ fontSize: "9px", fontWeight: "600" }}
                >
                  3 Mei 2024 - Gizmap Channel
                </div>
                <div
                  className="text-primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "11px",
                  }}
                >
                  Perbaikan Iphone Metode Gus Samsudin
                </div>
              </div>
            </div>

            <div className="d-flex mb-1">
              <div
                className="bg-primary mb-1 d-flex flex-shrink-0"
                style={{
                  height: "60px",
                  width: "120px",
                  border: "2px solid var(--primary-color)",
                  borderRadius: "3px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/podcast1.png')",
                }}
              ></div>
              <div className="d-flex flex-column ms-2">
                <div
                  className=""
                  style={{ fontSize: "9px", fontWeight: "600" }}
                >
                  3 Mei 2024 - Gizmap Channel
                </div>
                <div
                  className="text-primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "11px",
                  }}
                >
                  Perbaikan Iphone Metode Gus Samsudin
                </div>
              </div>
            </div>

            <div className="d-flex mb-1">
              <div
                className="bg-primary mb-1 d-flex flex-shrink-0"
                style={{
                  height: "60px",
                  width: "120px",
                  border: "2px solid var(--primary-color)",
                  borderRadius: "3px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/podcast1.png')",
                }}
              ></div>
              <div className="d-flex flex-column ms-2">
                <div
                  className=""
                  style={{ fontSize: "9px", fontWeight: "600" }}
                >
                  3 Mei 2024 - Gizmap Channel
                </div>
                <div
                  className="text-primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "11px",
                  }}
                >
                  Perbaikan Iphone Metode Gus Samsudin
                </div>
              </div>
            </div>

            <div className="d-flex mb-1">
              <div
                className="bg-primary mb-1 d-flex flex-shrink-0"
                style={{
                  height: "60px",
                  width: "120px",
                  border: "2px solid var(--primary-color)",
                  borderRadius: "3px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: "url('/assets/upload/podcast1.png')",
                }}
              ></div>
              <div className="d-flex flex-column ms-2">
                <div
                  className=""
                  style={{ fontSize: "9px", fontWeight: "600" }}
                >
                  3 Mei 2024 - Gizmap Channel
                </div>
                <div
                  className="text-primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "11px",
                  }}
                >
                  Perbaikan Iphone Metode Gus Samsudin
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default PodCast;
