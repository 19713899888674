import React from "react";
import Slider from "react-slick";
import "./PromoSlider.css";

import { useTranslation } from "react-i18next";

import PromoImageBox from "./PromoImageBox";

import SliderMenuTab from "../slidermenutab/SliderMenuTab";

const PromoSlider = () => {
  const { t, i18n } = useTranslation();

  var settingsH = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex flex-column flex-grow-1">

        <SliderMenuTab/>

        <div className="row g-0 flex-grow-1">
          <div className="col" style={{ overflowX: "hidden",overflowY: "hidden" }}>
            <Slider {...settingsH}>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon1.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon2.jpg" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon3.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon4.jpg" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon5.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon6.jpg" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon7.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon8.jpg" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex flex-column">
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon9.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon10.jpg" />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon11.jpg" />
                    </div>
                    <div className="col">
                      <PromoImageBox backgroundImage="/assets/upload/diskon12.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoSlider;
