import React from "react";

import PublicLayout from "../../_layout/PublicLayout";
import "./Terms.css";

const Terms = () => {
  return (
    <PublicLayout>
      <div className="d-flex flex-column w-100 h-100 ContentContainer">
        <div className="w-100 h-100 p-3" style={{ overflowY: "auto" }}>
          <h3>Terms of Service</h3>

          <p>Terms of Service for Gizmap</p>
          <p></p>
          <p>Effective Date: 31-05-2024</p>
          <p></p>
          <p>1. Acceptance of Terms</p>
          <p></p>
          <p>
            By downloading, installing, and using Gizmap , you agree to comply with and be bound by these Terms of
            Service. If you do not agree to these Terms, you must
            not use the App.
          </p>
          <p></p>
          <p>2. Changes to Terms</p>
          <p></p>
          <p>
            We reserve the right to modify these Terms at any time. We will
            notify you of any changes by updating the Terms on our website and
            within the App. Your continued use of the App after any such changes
            constitutes your acceptance of the new Terms.
          </p>
          <p></p>
          <p>3. Use of the App</p>
          <p></p>
          <p>
            You agree to use the App only for lawful purposes and in accordance
            with these Terms. You agree not to use the App:
          </p>
          <p></p>
          <p>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </p>
          <p>
            To exploit, harm, or attempt to exploit or harm minors in any way by
            exposing them to inappropriate content or otherwise.
          </p>
          <p>
            To transmit, or procure the sending of, any advertising or
            promotional material without our prior written consent.
          </p>
          <p>
            To impersonate or attempt to impersonate Gizmap,
            a Gizmap employee, another user, or any other
            person or entity.
          </p>
          <p>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the App, or which, as determined by us, may harm
            Gizmap or users of the App or expose them to
            liability.
          </p>
          <p>4. User Accounts</p>
          <p></p>
          <p>
            To access some features of the App, you may be required to create an
            account. You must provide accurate and complete information when
            creating your account. You are responsible for maintaining the
            confidentiality of your account login information and for all
            activities that occur under your account.
          </p>
          <p></p>
          <p>5. Intellectual Property Rights</p>
          <p></p>
          <p>
            The App and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by Gizmap, its
            licensors, or other providers of such material and are protected by
            copyright, trademark, patent, trade secret, and other intellectual
            property or proprietary rights laws.
          </p>
          <p></p>
          <p>6. Fees and Payments</p>
          <p></p>
          <p>
            Certain features of the App may be offered for a fee. You agree to
            pay all fees and charges associated with your use of such features.
            All fees are non-refundable, except as required by law.
          </p>
          <p></p>
          <p>7. Privacy</p>
          <p></p>
          <p>
            Your use of the App is also governed by our Privacy Policy, which
            can be found at <a href="/privacy">privacy</a>. By using the App, you
            consent to the collection and use of your information as outlined in
            the Privacy Policy.
          </p>
          <p></p>
          <p>8. Disclaimer of Warranties</p>
          <p></p>
          <p>
            The App is provided on an "as is" and "as available" basis, without
            any warranties of any kind, either express or implied. Neither
            Gizmap nor any person associated with Gizmap makes any warranty or representation with respect
            to the completeness, security, reliability, quality, accuracy, or
            availability of the App.
          </p>
          <p></p>
          <p>9. Limitation of Liability</p>
          <p></p>
          <p>
            To the fullest extent permitted by law, in no event will Gizmap, its affiliates, or their licensors,service
            providers, employees, agents, officers, or directors be liable for
            damages of any kind, under any legal theory, arising out of or in
            connection with your use, or inability to use, the App, any websites
            linked to it, any content on the App, or such other websites,
            including any direct, indirect, special, incidental,
            consequential,or punitive damages, including but not limited to,
            personal injury, pain and suffering, emotional distress, loss of
            revenue, loss of profits, loss of business or anticipated savings,
            loss of use, loss of goodwill, loss of data, and whether caused by
            tort (including negligence), breach of contract, or otherwise, even
            if foreseeable.
          </p>
          <p></p>
          <p>10. Indemnification</p>
          <p></p>
          <p>
            You agree to defend, indemnify, and hold harmless Gizmap, its affiliates, licensors, and service providers,and its
            and their respective officers, directors, employees,contractors,
            agents, licensors, suppliers,successors, and assigns from and
            against any claims, liabilities, damages, judgments, awards, losses,
            costs, expenses, or fees (including reasonable attorneys' fees)
            arising out of or relating to your violation of these Terms or your
            use of the App, including, but not limited to, your User
            Contributions, any use of the App's content, services, and products
            other than as expressly authorized in these Terms or your use of any
            information obtained from the App.
          </p>
          <p></p>
          <p>11. Termination</p>
          <p></p>
          <p>
            We may terminate or suspend your account and bar access to the App
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of these Terms. If you wish to
            terminate your account, you may simply discontinue using the App.
          </p>
          <p></p>
          <p>12. Governing Law</p>
          <p></p>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of Indonesia, without regard to its conflict of law
            provisions. Our failure to enforce any right or provision of these
            Terms will not be considered a waiver of those rights. If any
            provision of these Terms is held to be invalid or unenforceable by a
            court, the remaining provisions of these Terms will remain in
            effect. These Terms constitute the entire agreement between us
            regarding our App and supersede and replace any prior agreements we
            might have had between us regarding the App.
          </p>
          <p></p>
          <p>13. Changes to Terms</p>
          <p></p>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will provide
            at least 30 days' notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion. By continuing to access or use our App after any
            revisions become effective, you agree to be bound by the revised
            terms. If you do not agree to the new terms, you are no longer
            authorized to use the App.
          </p>
          <p></p>
          <p>14. Contact Us</p>
          <p></p>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <p></p>
          <p>Gizmap</p>
          {/* <p>[Your Address]</p>
          <p>[Your City, State, Zip Code]</p>
          <p>[Contact Email Address]</p>
          <p>[Contact Phone Number]</p> */}
        </div>
      </div>
    </PublicLayout>
  );
};

export default Terms;
